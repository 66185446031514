import React, {useContext, useEffect, useRef, useState} from "react";
import AppContext from "../../appContext";
import LabminController from "../../controllers/LabminController";
import PagedSearchTable, {PagedTableFunctions} from "../../components/PagedSearchTable";
import {wrapLoader} from "../../wrapper";
import Dialog from "../../components/Dialog";
import Cultivars from "../crop/Cultivars";
import Crop from "../crop/Crop";
import {showSuccessOrFailed} from "../../Snacks";
import GrowthStages from "../crop/GrowthStages";
import LabminCrop from "../../controllers/LabminCrop";
import FirestoreCrop from "../../controllers/FirestoreCrop";
import CropController from "../../controllers/CropController";
import {useValidation} from "../../validation";
import SelectNumber from "../../components/SelectNumber";
import Originate from "../../controllers/Originate";

function emptyLabminCrop(): LabminCrop {
    return {
        id: 0,
        name: null,
        shortCode: null,
        lab: null
    }
}

function emptyFirestoreCrop(): FirestoreCrop {
    return {
        id: 0,
        name: null,
        macroGraphId: null,
        microGraphId: null,
        molybdenumGraphId: null,
        nickelGraphId: null,
        nitrateGraphId: null,
        ammoniumGraphId: null,
        labminCropId: null,
        imageHash: null,
        imagePath: null,
        linkedNames: [],
        active: false,
        factor: null,
        originate: Originate.International
    }
}

const Crops: React.FC = () => {
    const context = useContext(AppContext);
    const pagedTableRef = useRef<PagedTableFunctions<FirestoreCrop>>()

    const [labminCrops, setLabminCrops] = useState<LabminCrop[]>([emptyLabminCrop()])
    const [crops, setCrops] = useState<FirestoreCrop[]>([emptyFirestoreCrop()])
    const [cropId, setCropId] = useState<number | null>(null)
    const [loader, setLoader] = useState<boolean>(false)
    const [linkedFrom, setLinkedFrom] = useState<number>(0)
    const [linkedTo, setLinkedTo] = useState<number>(0)
    const [popup, setPopup] = useState<'growth' | 'cultivars' | 'crop' | 'none'>('none');

    const [linkCrop, setLinkCrop] = useState<number>(0)
    const [showLinkWarning, setShowLinkWarning] = useState<boolean>(false)

    useEffect(() => {
        LabminController.allCrops().then(resp => {
            setLabminCrops(resp.data)
        })

        CropController.all().then(resp => {
            setCrops(resp.data)
        })
    }, [])

    const validation = useValidation({
        id: linkCrop > 0
    });

    function assignCrop(labminCropId: string, row: FirestoreCrop) {
        let val = parseInt(labminCropId)

        // set null explicitly if empty
        wrapLoader(context, CropController.assignLab({
            cropId: row.id,
            labminCropId: val,
        }), () => pagedTableRef.current?.refresh())
    }

    function editGrowth(id: number) {
        setPopup('growth')
        setCropId(id)
    }

    function editCultivar(id: number) {
        setCropId(id)
        setPopup('cultivars')
    }

    function editCrop(row: FirestoreCrop) {
        setPopup('crop')
        setCropId(row.id)
    }

    function closeCrop() {
        setCropId(null)
        setPopup('none')
        pagedTableRef.current?.refresh();
    }

    function cancelDeleteCrop() {
        setLinkCrop(0)
        setShowLinkWarning(false)
    }

    function linkAndDelete() {
        if (!validation.validate()) return;

        if (cropId) {
            wrapLoader(context, showSuccessOrFailed(context, CropController.delete({cropId: cropId, linkCropId: linkCrop})), () => {
                pagedTableRef.current?.refresh();
                cancelDeleteCrop();
            })
        }
    }

    function deleteCropWarning(id: number, linkedId: number|null) {
        setCropId(id)
        setPopup('none')
        setShowLinkWarning(true)
        if (linkedId) {
            setLinkCrop(linkedId)
        }
    }

    return (
        <div className="bg-white p-2 shadow">
            <PagedSearchTable componentRef={pagedTableRef} call={CropController.paged} columns={[
                {
                    header: 'Id',
                    key: 'id',
                    row: (row) => row.id
                },
                {
                    header: 'Name',
                    key: 'name',
                    row: (row) => row.name
                },
                {
                    header: 'Labmin',
                    key: 'labmin',
                    row: (row) =>
                        <select className="sm-input-select" value={row.labminCropId ?? 0}
                                onChange={v => assignCrop(v.target.value, row)}>
                            <option value={0} key="empty" disabled={true}>Not Selected</option>
                            {labminCrops.map(crop => <option key={crop.id} value={crop.id}>{crop.name}</option>)}
                        </select>
                },
                {
                    header: 'Alternative Names',
                    key: 'linked',
                    row: row  => <div className='flex flex-wrap'> {row.linkedNames.map(c => <div key={c}
                                                                      className="text-xs bg-gray-500 rounded-md text-white inline-block px-2 my-1 mx-1">{c}</div>)}</div>
                },
                {
                    header: <div className="text-right">Actions</div>,
                    key: 'actions',
                    row: (row) =>

                        <div className="text-right">
                            <div className="btn-primary btn-sm" onClick={() => editGrowth(row.id)}>growth</div>
                            <div className="btn-primary btn-sm" onClick={() => editCultivar(row.id)}>cultivars</div>
                            <div className="btn-primary btn-sm" onClick={() => editCrop(row)}>edit</div>
                            <button className="mr-1 btn-error btn-sm"
                                    onClick={() => deleteCropWarning(row.id, null)}>x</button>
                        </div>
                },
            ]}/>


            {cropId != null && popup == 'cultivars' ?
                <Dialog title="Cultivar Data" show={true} setShow={() => setPopup('none')}>
                    <Cultivars cropId={cropId} close={() => setCropId(null)}/>
                </Dialog>
                : null}

            {cropId != null && popup === 'crop'
                ? <Dialog title="Crop Data" show={true} setShow={() => setPopup('none')}>
                    <Crop cropId={cropId} close={() => closeCrop()}/>
                </Dialog>
                : null}

            {cropId != null && popup === 'growth'
                ? <Dialog title="Growth Stages" show={true} setShow={() => setPopup('none')}>
                    <GrowthStages cropId={cropId} close={() => setCropId(null)}/>
                </Dialog>
                : null}

            <Dialog title={`Link to`} show={showLinkWarning} setShow={cancelDeleteCrop} body={<>
                <div className='flex p-2 items-center'>
                    <div className='whitespace-nowrap pr-2'>
                        Link to:
                    </div>

                    <SelectNumber className={!validation.rules.id ? 'ring-2 ring-red-500' : ''} options={crops} textFunc={t => t.name ? t.name + ' - ' + t.id.toString() : t.id.toString()} valueFunc={v => v.id} value={linkCrop} onChange={v => setLinkCrop(v)}/>
                </div>

                <div className="text-right bg-white p-2 border-t sticky bottom-0">
                    <button className="btn btn-error" onClick={() => cancelDeleteCrop()}>
                        Cancel
                    </button>
                    <button className='btn btn-primary' onClick={() => linkAndDelete()}>
                        Link & Delete
                    </button>
                </div>
            </>}/>
        </div>
    )
}

export default Crops;