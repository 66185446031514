import React, {PropsWithChildren} from 'react'
import ReactDOM from 'react-dom'
import AuthController from "./controllers/AuthController";
import Login from "./pages/login";
import AppContextProvider from "./AppContextProvider";
import {BrowserRouter, Navigate, Route, Routes, useLocation} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import './index.css'
import Home from "./Home";
import Users from "./pages/Users";
import Roles from "./pages/Roles";
import Addresses from "./pages/Addresses";
import Distributors from "./pages/Distributors";
import WindowInitial from "./controllers/WindowInitial";
import Leafs from "./pages/Leafs";
import Submissions from "./pages/Submissions";
import Labmin from "./pages/Labmin";
import LabJobs from "./pages/lab/Jobs";
import Language from "./pages/languages/Language";
import Tissues from "./pages/itestcarbohydrates/Tissues";
import GrowthStages from "./pages/itestcarbohydrates/GrowthStages";
import Crops from "./pages/submission/Crops";
import DrisGraphs from "./pages/DrisGraphs";
import * as Sentry from '@sentry/react'
import Settings from "./pages/Settings";
import Token from "./pages/Token";
import CarbohydratesTabs from "./pages/CarbohydratesTabs";
import ManageFarms from "./pages/ManageFarms";


function isLocalhost() {
    return window.location.host.includes('localhost');
}

function initializeSentry (userName: string) {
    if (isLocalhost()) {
        return
    }
    Sentry.init({
        dsn: 'https://d7b0926823f14b5c86af744c1e496bbd@o998114.ingest.sentry.io/6261821',
        integrations: [Sentry.browserTracingIntegration()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    })

    Sentry.setUser({
        username: userName
    })
}



const ErrorPage: React.FC = () => {
    return (
        <div className="h-screen w-full flex">
            <div className="m-auto">
                <img className="w-40 md:w-full" src="logo.png" alt=""/>

                <h1 className="my-2 text-gray-800 font-bold text-2xl">
                    Oops, something went wrong.
                </h1>
                <div className="my-2 text-gray-800">The exact error has been logged and our developers have been notified.</div>
                <a href="/"
                   className="cursor-pointer poin inline-block border rounded md py-4 px-8 text-center bg-primary-600 text-white hover:bg-primary-70">
                    Start over
                </a>
            </div>
        </div>
    )
}

AuthController.initial().then(resp => {

    if (window.localStorage.getItem('version') !=resp.data.version) {
        window.localStorage.setItem('version',resp.data.version)
        window.location.reload()
    }
    
    initializeSentry(resp.data.name)
    
    ReactDOM.render(
        <React.StrictMode>
            <Sentry.ErrorBoundary fallback={<ErrorPage/>}>
            <AppContextProvider initial={resp.data}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<Login />}/>
                        <Route path="/token" element={< Token />}/>
                        <Route path="/" element={<RequireAuth appState={resp.data}><Home/></RequireAuth>}>
                            <Route path="/leafs" element={<Leafs/>}/>
                            <Route path="/carbohydrates" element={<CarbohydratesTabs/>}/>
                            <Route path="/carbohydratestissues" element={<Tissues/>}/>
                            <Route path="/carbohydratesgrowths" element={<GrowthStages/>}/>
                            <Route path="/labmins" element={<Labmin/>}/>
                            <Route path="/crops" element={<Crops/>}/>
                            <Route path="/drisgraphs" element={<DrisGraphs/>}/>
                            <Route path="/labjobs" element={<LabJobs/>}/>
                            <Route path="/users" element={<Users/>}/>
                            <Route path="/roles" element={<Roles/>}/>
                            <Route path="/languages" element={<Language/>}/>
                            <Route path="/addresses" element={<Addresses/>}/>
                            <Route path="/distributors" element={<Distributors/>}/>
                            <Route path="/submissions" element={<Submissions/>}/>
                            <Route path="/settings" element={<Settings/>}/>
                            <Route path="/farms" element={<ManageFarms/>}/>
                        </Route>
                        <Route path="*" element={<Login/>}/>
                    </Routes>
                </BrowserRouter>
            </AppContextProvider>
            </Sentry.ErrorBoundary>

        </React.StrictMode>,
        document.getElementById('root')
    );
}).catch(err => {
    console.log(err)
    document.getElementById('loader')!.innerHTML = 'Error loading app'
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

const RequireAuth: React.FC<PropsWithChildren<{ appState: WindowInitial }>> = (props) => {
    const location = useLocation()

    if (!props.appState.authenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace/>
    }

    return <>{props.children}</>
}
